import React,{useEffect,useState} from 'react'
import style from './Profile.module.css'
import { AiFillEyeInvisible } from "react-icons/ai";
const Profile = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const[user,setUser]=useState([])
  const [type, setType] = useState(true);
    async function userData(){
      await fetch("https://services.1o1learnersclub.com/account/get",{
        method:"POST",
        body:JSON.stringify({
        }),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${key}`,
        },
      }).then((res)=>res.json()).then((data)=>{
        console.log(data.data[0]);
        setUser(data.data)
      })
    }
    useEffect(()=>{
      userData()
    },[key])
  return (
    <div className={style.Container}>
        <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>UserName</p>
        </div>
      </div>
      <div className={style.List}>
        <table className={style.table}>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Mobilenumber</th>
            <th>Password</th>
            <th>Action</th>
          </tr>
          {user.length>0&&user.map((item)=>{
            return(
              <tr>
            <td>{item.Username}</td>
            <td>{item.Email}</td>
            <td>{item.ContactNumber}</td>
            <td><input value={item.Password} readOnly className={style.passwordInput} type={type?"password":"text"}/><AiFillEyeInvisible  onClick={() => {
                type ? setType(false) : setType(true);
              }} className={style.eye}/></td>
            <td>
              <div className={style.Buttons}>
                <button className={style.Edit}>Edit</button>
              </div>
            </td>
          </tr>
            )
              
            })}
          
        </table>
      </div>
    </div>
  )
}

export default Profile