import React, { useState, useEffect } from "react";
import style from "./Login.module.css";
import { useNavigate } from "react-router-dom";
import { BsFillEyeFill } from "react-icons/bs";
import { BsFillEyeSlashFill } from "react-icons/bs";
import finants from "../../assets/1-page.png";
import { baseUrl } from "../Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [organization, setOrgnization] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function login() {
    await fetch(`${baseUrl}/login/users`, {
      method: "POST",
      body: JSON.stringify({
        Username: organization,
        Password: password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          localStorage.setItem("user",true)
          localStorage.setItem("user_id", data.data[0].user_id);
  
          navigate("/");
        } else {
          toast.error(data.description);
        }
      });
  }

  return (
    <div className={style.Container}>
      <ToastContainer />
      <div className={style.Login}>
        <div className={style.form}>
          <div className={style.logo}>
          <img src="https://www.tasshamjit.com/wp-content/uploads/2020/09/cropped-logo_main-1.png" alt="" />
            {/* <img src={finants} alt="" /> */}
          </div>
          <div className={style.loginForm}>
            <input
              className={style.input}
              type="text"
              value={organization}
              onChange={(e) => {
                setOrgnization(e.target.value);
              }}
              placeholder="User Name"
            />
            <div className={style.password}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {showPassword ? (
                <BsFillEyeSlashFill onClick={togglePasswordVisibility} />
              ) : (
                <BsFillEyeFill onClick={togglePasswordVisibility} />
              )}
            </div>

            <button
              onClick={() => {
                login();
              }}
            >
              Sign In
            </button>
          </div>
          {/* <div className={style.signUp}>
            <p>Don't have an account?</p>
            <button
              onClick={() => {
                navigate("/register");
              }}
            >
              Sign Up
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
