import React, { useEffect, useState } from "react";
import style from "./Home.module.css";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";

const Home = () => {
  const navigate = useNavigate();

  const user = localStorage.getItem("user");
  const user_id = localStorage.getItem("user_id");

  const [urlValue,setUrlValue] = useState("");

 

  async function profileDetails(){
    await fetch(`${baseUrl}/user/profile`,{
      method: "POST",
      body: JSON.stringify({
        user_id: user_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res.json())
      .then((data) => {
        console.log(data,'url');
        if(data.status === true){
          setUrlValue(data.data[0].url)
        }
      });
  }

  useEffect(() => {
    if (user === null || user === undefined) {
      navigate("/login");
    }
  }, [user]);
  
  useEffect(() => {
    profileDetails()
  }, [user_id])
  

  return (
    <div className={style.Container}>
      <div className={style.HomeContent}>
        <iframe
          src={urlValue}
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Home;
