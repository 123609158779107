import React,{useState} from 'react'
import ReactPaginate from 'react-paginate';
import style from "./Pagination.module.css"

const Pagination = ({ pageCount, onPageChange,onItemsPerPageChange }) => {
  const [itemsPerPage, setItemsPerPage] = useState(20); 
  const handleItemsPerPageChange = (e) => {
    const selectedItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(selectedItemsPerPage);
    onItemsPerPageChange(selectedItemsPerPage);
  };
  return (
    <div className={style.paginationContainer}>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={5} // Number of visible page links
        marginPagesDisplayed={1} // Number of page links to display at the beginning and end
        onPageChange={onPageChange}
        containerClassName={style.pagination}
        activeClassName={style.Active}
        previousLabel={'Prev'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageClassName={style.page}
        previousClassName={style.previous}
        nextClassName={style.next}
      />
      <div className={style.itemsPerPageDropdown}>
        <label>Show<select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select> entries </label>
        
      </div>
    </div>
    
  )
}

export default Pagination