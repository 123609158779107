import { CLUB_FAIL, CLUB_REQUEST, CLUB_SUCCESS } from "../constants/List";

export const ClubReducer = (state = { clubs: [] }, action) => {
    switch (action.type) {
        case CLUB_REQUEST:
            return {
                loading: true,
            };

        case CLUB_SUCCESS:
            return {
                loading: false,
                clubs: action.payload.data,
            };

        case CLUB_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

