import React, { useEffect, useState } from "react";
import style from "./Organization.module.css";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineViewGrid } from "react-icons/hi";


const Organization = () => {
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [editName, setEditName] = useState("");
  const [description, setDescription] = useState("");
  const key = JSON.parse(localStorage.getItem("access-token"));
  const [orgData, setOrgData] = useState([]);
  const [orgEdit, setOrgEdit] = useState(false);
  const [orgnizationDelete, setOrganizationDelete] = useState(false);
  const [orgDescription, setOrgDescription] = useState("");
  const [orgId, setOrgId] = useState("");
  const [deleteOrgId, setDeleteOrgId] = useState("");

  const adminStatus = localStorage.getItem("adminStatus");
  console.log(adminStatus);

  async function organizationGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(
      "https://services.1o1learnersclub.com/org/get/admin/per_users",
      {
        method: "POST",
        body: JSON.stringify({ user_id: userId }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setOrgData(data.data);
      });
  }

  async function OrganisationAdd() {
    setPopup(false);
    await fetch("https://services.1o1learnersclub.com/organization/add", {
      method: "POST",
      body: JSON.stringify({ org_name: orgName, description: description }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        organizationGet();
      });
  }

  async function orgUpdate(e) {
    await fetch("https://services.1o1learnersclub.com/organization/update", {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
        org_name: editName,
        description: orgDescription,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setOrgEdit(false);
          organizationGet();
          setEditName("");
          setOrgDescription("");
        }
      });
  }

  async function orgDelete() {
    await fetch("https://services.1o1learnersclub.com/organization/delete", {
      method: "POST",
      body: JSON.stringify({
        org_id: deleteOrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          organizationGet();
          setOrganizationDelete(false);
        }
      });
  }

  useEffect(() => {
    organizationGet();
  }, [key]);

  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>Organizations</p>
        </div>
        <div className={style.HeadButton}>
          {adminStatus === "true" ? (
            <button
              onClick={() => {
                setPopup(true);
              }}
            >
              Create
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={style.orgSection}>
        <div className={style.Grid}>
          {orgData && orgData.length > 0
            ? orgData.map((item, index) => {
              return (
                <div key={index} className={style.Cards}>
                  <div className={style.cardTop}>
                    <p>{item.org_name}</p>
                  </div>
                  <div className={style.cardBottom}>
                    <div className={style.iconContainer}>
                      <HiOutlineViewGrid
                        className={style.viewButton}
                        onClick={() => {
                          navigate("/club", {
                            state: {
                              organization: item.org_id,
                            },
                          });
                        }}
                      />
                      <span className={style.tooltipText}>View</span>
                    </div>
                    <div className={style.iconContainer}>
                      <BiEdit
                        className={style.editButton}
                        onClick={() => {
                          setOrgEdit(true);
                          setEditName(item.org_name);
                          setOrgDescription(item.description);
                          setOrgId(item.org_id);
                        }}
                      />
                      <span className={style.tooltipText}>Edit</span>
                    </div>
                    {adminStatus === "true" ? (
                      <div className={style.iconContainer}>
                        <RiDeleteBinLine
                          className={style.deleteButton}
                          onClick={() => {
                            setOrganizationDelete(true);
                            setDeleteOrgId(item.org_id);
                          }}
                        />
                        <span className={style.tooltipText}>Delete</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              );
            })
            : ""}
        </div>
      </div>

      {popup ? (
        <>
          <div
            onClick={() => {
              setPopup(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setPopup(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              <input
                type="text"
                name=""
                id=""
                required
                onChange={(e) => {
                  setOrgName(e.target.value);
                }}
                placeholder="Organisation"
              />
              <input
                type="text"
                name=""
                id=""
                required
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                placeholder="Description"
              />
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  OrganisationAdd();
                }}
              >
                Create
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setPopup(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {orgEdit ? (
        <>
          <div
            onClick={() => {
              setOrgEdit(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.AddressSection}>
            <div className={style.popupClose}>
              <AiOutlineClose
                onClick={() => {
                  setOrgEdit(false);
                }}
              />
            </div>
            <div action="" className={style.popupSection}>
              <input
                type="text"
                value={editName}
                required
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
                placeholder="Organization"
              />
              <input
                type="text"
                value={orgDescription}
                required
                onChange={(e) => {
                  setOrgDescription(e.target.value);
                }}
                placeholder="Description"
              />
            </div>
            <div className={style.popupButton}>
              <button
                className={style.create}
                onClick={() => {
                  orgUpdate();
                }}
              >
                Update
              </button>
              <button
                className={style.cancel}
                onClick={() => {
                  setOrgEdit(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {orgnizationDelete ? (
        <>
          <div
            onClick={() => {
              setOrganizationDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setOrganizationDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    orgDelete();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setOrganizationDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Organization;
