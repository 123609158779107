import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {  RoleReducer, profileReducer, userReducer } from './reducer/User'
import { ClubReducer } from './reducer/List'

const reducers = combineReducers({
    userReducer: userReducer,
    profileData: profileReducer,
    roleData: RoleReducer,
    clubData: ClubReducer,

})
const middleware = [thunk]
const InitialState = {

}

const store = createStore(reducers, InitialState, composeWithDevTools(applyMiddleware(...middleware)))


export default store;