import {
    LOGIN_FAIL,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    PROFILE_FAIL,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
    ROLE_FAIL,
    ROLE_REQUEST,
    ROLE_SUCCESS,
} from "../constants/user";

export const userReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                loading: true,
                authenticated: false,
            }
        case LOGIN_SUCCESS:
            return {
                loading: false,
                users: action.payload,
                authenticated: true,
            }
        case LOGIN_FAIL:
            return {
                loading: false,
                error: action.payload,
                authenticated: false,
            }

        default:
            return {
                ...state,
            }
    }
}
export const profileReducer = (state = { profile: [] }, action) => {
    switch (action.type) {
        case PROFILE_REQUEST:
            return {
                loading: true,
            };

        case PROFILE_SUCCESS:
            return {
                loading: false,
                profile: action.payload.data[0],
            };

        case PROFILE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export const RoleReducer = (state = { roles: [] }, action) => {
    switch (action.type) {
        case ROLE_REQUEST:
            return {
                loading: true,
            };

        case ROLE_SUCCESS:
            return {
                loading: false,
                roles: action.payload.data,
            };

        case ROLE_FAIL:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

