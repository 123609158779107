import React, { useState } from "react";
import style from "./Register.module.css";
import finants from "../../assets/1-page.png";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { BsFillEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Register = () => {
  const navigate = useNavigate();

  const [organization, setOrgnization] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const confirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (password !== newConfirmPassword) {
      setPasswordError("Passwords do not match");
    } else {
      setPasswordError("");
    }
  };

  const registerForm = () => {
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (organization === "") {
      toast.error("Please enter organization name");
    }
    if (phone === "") {
      toast.error("Please enter phone number");
    }
    if (email === "") {
      toast.error("Please enter email");
    }
    if (password === "") {
      toast.error("Please enter password");
    }
    if (confirmPassword === "") {
      toast.error("Please enter password");
    }

    if (!phoneRegex.test(phone)) {
      toast.error("Please enter valid phone number");
    }
    if (!emailRegex.test(email)) {
      toast.error("Please enter valid email");
    } else {
      register();
    }
  };

  async function register() {
    await fetch(`${baseUrl}/organization/signup`, {
      method: "POST",
      body: JSON.stringify({
        org_name: organization,
        email: email,
        password: password,
        contact: phone,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          navigate("/");
        } else {
          toast.error(data.message);
        }
      });
  }

  return (
    <div className={style.Container}>
      <ToastContainer />
      <div className={style.Register}>
        <div className={style.form}>
          <div className={style.logo}>
            <img src={finants} alt="" />
          </div>
          <div className={style.loginForm}>
            <input
              className={style.input}
              type="text"
              value={organization}
              onChange={(e) => {
                setOrgnization(e.target.value);
              }}
              placeholder="Organization Name"
            />

            <input
              className={style.input}
              type="text"
              name=""
              id=""
              placeholder="Phone"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <input
              className={style.input}
              type="email"
              name=""
              id=""
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <div className={style.password}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              {showPassword ? (
                <BsFillEyeSlashFill onClick={togglePasswordVisibility} />
              ) : (
                <BsFillEyeFill onClick={togglePasswordVisibility} />
              )}
            </div>
            <div className={style.password}>
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
              {showConfirmPassword ? (
                <BsFillEyeSlashFill onClick={confirmPasswordVisibility} />
              ) : (
                <BsFillEyeFill onClick={confirmPasswordVisibility} />
              )}
            </div>

            <button
              onClick={() => {
                registerForm();
              }}
            >
              Sign Up
            </button>
            <div className={style.Error}>
              {passwordError && <p>{passwordError}</p>}
            </div>
          </div>

          <div className={style.signUp}>
            <p>Already have an account?</p>
            <button
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
