export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAIL = "LOGIN_FAIL"

export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"

export const PROFILE_REQUEST = "PROFILE_REQUEST"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_FAIL = "PROFILE_FAIL"

export const ROLE_REQUEST = "ROLE_REQUEST"
export const ROLE_SUCCESS = "ROLE_SUCCESS"
export const ROLE_FAIL = "ROLE_FAIL"

