import React, { useState, useEffect } from "react";
import style from "./User.module.css";
import { BsPersonCircle } from "react-icons/bs";
import { RiShareForwardFill } from "react-icons/ri";
import { RiDeleteBinLine } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { FcSearch } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";
import { BiLockOpenAlt } from "react-icons/bi";
import { baseUrl } from "../Url";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RoleGet } from "../../action/User";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import Pagination from "../Pagination/Pagination";

const User = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));
  const adminId = localStorage.getItem("adminUserId");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { roles } = useSelector((state) => state.roleData);
  console.log(roles);

  const [userData, setUserData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [selectedCLubId, setSelectedClubId] = useState("");
  const [clubList, setClubList] = useState([]);
  const [clubReadOnly, setClubReadOnly] = useState(true);
  const [userShow, setUserShow] = useState(false);
  const [roleChange, setRoleChange] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [mentorChange, setMentorChange] = useState(false);
  const [mentorList, setMentorList] = useState([]);
  const [selectedMentorId, setSelectedMentorId] = useState("");
  const [mappedMentorList, setMappedMentorList] = useState(false);
  const [singleMentorList, setSingleMentorList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [memberCreate, setMemberCreate] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [editFirstName, setEditFirstName] = useState("");
  const [editSecondName, setEditSecondName] = useState("");
  const [editUserName, setEditUserName] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editPhone, setEditPhone] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [addMemberShow, setAddMemberShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [clubName, setClubName] = useState("");
  const [clubSelected, setClubSelected] = useState(false);
  const [clubSelectShow, setClubSelectShow] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [EditClubSelectShow, setEditClubSelectShow] = useState(false);
  const [userId, setUserId] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [editClubs, setEditClubs] = useState([]);
  const [memberDelete, setMemberDelete] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const handleRoleChange = (organizationId) => {
    const data = {
      orgId: organizationId,
      clubId: "",
    };
    console.log(data);
    dispatch(RoleGet(data));
  };
  const handleClubRoleChange = (id) => {
    const data = {
      orgId: selectedOrgId,
      clubId: id,
    };
    console.log(data);
    dispatch(RoleGet(data));
  };

  const itemsPerPage = 12; // Adjust as needed
  const users = userData;
  const totalUsers = users.length;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayUsers = users.slice(startIndex, endIndex);

  const validateForm = () => {
    const errors = {};

    if (firstName.trim() === "") {
      errors.firstName = "First Name is required";
    }

    if (secondName.trim() === "") {
      errors.secondName = "Second Name is required";
    }

    if (userName.trim() === "") {
      errors.userName = "User Name is required";
    }

    if (password.trim() === "") {
      errors.password = "Password is required";
    }

    if (phone.trim() === "") {
      errors.phone = "Phone is required";
    }

    // You can add more specific validation rules for email and role if needed

    if (selectedRoleId === "") {
      errors.selectedRole = "Role is required";
    }
    if (selectedClubs.length === 0) {
      errors.clubSelection = "Select at least one club";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const editForm = () => {
    const errors = {};

    if (editFirstName.trim() === "") {
      errors.editFirstName = "First Name is required";
    }

    if (editSecondName.trim() === "") {
      errors.editSecondName = "Second Name is required";
    }

    if (editUserName.trim() === "") {
      errors.editUserName = "User Name is required";
    }

    if (editPhone.trim() === "") {
      errors.editPhone = "Phone is required";
    }

    // You can add more specific validation rules for email and role if needed

    if (selectedRoleId === "") {
      errors.selectedRole = "Role is required";
    }
    if (editClubs.length === 0) {
      errors.editClubs = "At least one club must be selected";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (event) => {
    const clubId = event.target.id;
    if (event.target.checked) {
      // Add the selected club ID to the list
      setSelectedClubs((prevSelectedClubs) => [...prevSelectedClubs, clubId]);
    } else {
      // Remove the unselected club ID from the list
      setSelectedClubs((prevSelectedClubs) =>
        prevSelectedClubs.filter((id) => id !== clubId)
      );
    }
  };

  const handleClub = (clubID) => {
    if (Array.isArray(editClubs)) {
      if (editClubs.includes(clubID)) {
        // Use filter to remove the clubID from the array
        setEditClubs(editClubs.filter((id) => id !== clubID));
      } else {
        // Use spread operator to add the clubID to the array
        setEditClubs([...editClubs, clubID]);
      }
    } else {
      // Initialize selectedClubs as an array with the current club ID
      setEditClubs([editClubs, clubID]);
    }
  };

  //Organization list API
  async function OrgGet() {
    const userId = localStorage.getItem("adminUserId");
    await fetch(`${baseUrl}/org/get/admin/per_users`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "orgData");
        setOrgList(data.data);
      });
  }

  //CLub list API
  async function ClubGet(OrgId) {
    await fetch(`${baseUrl}/club/list`, {
      method: "POST",
      body: JSON.stringify({
        OrganizationID: OrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setClubList(data.data);
      });
  }

  //user list
  async function usersListByOrg(orgId) {
    await fetch(`${baseUrl}/user/get/by/org/club`, {
      method: "POST",
      body: JSON.stringify({
        org_id: orgId,
        club_id: "",
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserData(data.data);
      });
  }

  //user list
  async function usersList(clubId) {
    await fetch(`${baseUrl}/user/get/by/org/club`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
        club_id: clubId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setUserData(data.data);
      });
  }

  //role change
  async function changeRole(roleid, id) {
    await fetch(`${baseUrl}/user/role/change/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: id,
        role: roleid,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          usersList(selectedCLubId);
        }
      });
  }

  //mentor list
  async function allMentorList() {
    await fetch(`${baseUrl}/mentor/get/by/org`, {
      method: "POST",
      body: JSON.stringify({
        org_id: selectedOrgId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setMentorList(data.data);
        console.log(mentorList);
      });
  }

  //mentor update
  async function mentorUpdate(mentorID, UserID) {
    await fetch(`${baseUrl}/support/member/learner/mapping/add`, {
      method: "POST",
      body: JSON.stringify({
        SupportMemberID: mentorID,
        LearnerID: UserID,
        LearnerOrganizationID: selectedOrgId,
        LearnerClubID: selectedCLubId,
        MappingCreatedBy: adminId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          usersList(selectedCLubId);
        }
      });
  }

  //mapped mentor list
  async function mappedMentorView(UserID) {
    await fetch(`${baseUrl}/user/mapped/mentors`, {
      method: "POST",
      body: JSON.stringify({
        user_id: UserID,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSingleMentorList(data.data);
      });
  }

  //member add
  async function memberAdd(e) {
    const bodyData = {
      FirstName: firstName,
      MiddleName: "",
      LastName: secondName,
      Nickname: "",
      Username: userName,
      Password: password,
      DOB: "",
      Gender: "",
      ContactNumber: phone,
      Email: email,
      CommunicationAddress: "",
      PermanentAddress: "",
      BillingAddress: "",
      MemberRoles: selectedRoleId,
      CurrentAddress: "",
      SignupSource: "admin",
      org_id: selectedOrgId,
      role: selectedRoleName,
      club_id: selectedClubs,
    };
    const isFormValid = validateForm();

    if (isFormValid) {
      await fetch(`${baseUrl}/account/signup/by/admin`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success" && clubSelected === false) {
            usersListByOrg(selectedOrgId);
            setMemberCreate(false);
          } else if (data.status === "success" && clubSelected === true) {
            usersList(selectedCLubId);
            setMemberCreate(false);
          } else {
            setApiError(data.message);
          }
        });
    }
  }

  //member edit

  async function memberEdit(e) {
    const bodyData = {
      user_id: userId,
      FirstName: editFirstName,
      MiddleName: "",
      LastName: editSecondName,
      Nickname: "",
      Username: editUserName,
      DOB: "",
      Gender: "",
      ContactNumber: editPhone,
      Email: editEmail,
      CommunicationAddress: "",
      PermanentAddress: "",
      BillingAddress: "",
      MemberRoles: selectedRoleId,
      CurrentAddress: "",
      SignupSource: "admin",
      org_id: selectedOrgId,
      role: selectedRoleName,
      club_id: editClubs,
    };
    const isEditValid = editForm();

    if (isEditValid) {
      await fetch(`${baseUrl}/account/update/by/admin`, {
        method: "POST",
        body: JSON.stringify(bodyData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          if (data.status === "success" && clubSelected === false) {
            usersListByOrg(selectedOrgId);
            setUpdatePopup(false);
          } else if (data.status === "success" && clubSelected === true) {
            usersList(selectedCLubId);
            setUpdatePopup(false);
          } else {
            setApiError(data.message);
          }
        });
    }
  }
  //member deleting api

  async function DeleteMember() {
    await fetch(`${baseUrl}/user/delete/by/admin`, {
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true && clubSelected === false) {
          usersListByOrg(selectedOrgId);
          setMemberDelete(false);
        } else if (data.status === true && clubSelected === true) {
          usersList(selectedCLubId);
          setMemberDelete(false);
        }
      });
  }

  useEffect(() => {
    OrgGet();
  }, [key]);

  return (
    <div className={style.Container}>
      <div className={style.Header}>
        <div className={style.HeaderText}>
          <p>User Management</p>
        </div>
        <div className={style.HeadButton}>
          {addMemberShow ? (
            <button
              onClick={() => {
                setMemberCreate(true);
              }}
            >
              Add Member
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={style.select_section}>
        <div className={style.org_select}>
          <select
            name=""
            id=""
            onChange={(e) => {
              const OrgID = e.target.value;
              ClubGet(OrgID);
              setClubReadOnly(false);
              setSelectedOrgId(OrgID);
              setUserShow(true);
              usersListByOrg(OrgID);
              setAddMemberShow(true);
              handleRoleChange(OrgID);
            }}
          >
            <option value="">Select Organisations</option>
            {orgList.length > 0 &&
              orgList.map((org) => {
                return (
                  <option key={org.org_id} value={org.org_id}>
                    {org.org_name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className={style.club_select}>
          <select
            name=""
            id=""
            disabled={clubReadOnly}
            onChange={(e) => {
              const ClubID = e.target.value;
              if (ClubID !== "") {
                usersList(ClubID);
                setSelectedClubId(ClubID);
                const batchName = e.target.options[e.target.selectedIndex].text;
                setClubName(batchName);
                handleClubRoleChange(ClubID);
                setClubSelected(true);
              }
            }}
          >
            <option value="">Select Clubs/Batchs</option>
            {clubList.length > 0 &&
              clubList.map((club) => {
                return (
                  <option value={club.ClubID} key={club.ClubID}>
                    {club.ClubName}
                  </option>
                );
              })}
          </select>
        </div>
      </div>

      {userShow ? (
        <div className={style.userManagement}>
          <div className={style.UserManagementHeader}>
            <div className={style.Search}>
              <input type="search" name="" id="" placeholder="Search here" />
              <FcSearch />
            </div>
            <div className={style.buttonSection}>
              <button
                onClick={() => {
                  setRoleChange(true);
                }}
              >
                Role Change
              </button>
              <button
                onClick={() => {
                  setMentorChange(true);
                  allMentorList();
                }}
              >
                Mentor Mapping
              </button>
            </div>
          </div>

          <div className={style.List}>
            {displayUsers.length > 0 &&
              displayUsers.map((data, i) => {
                return (
                  <div className={style.userCards} key={i}>
                    <div className={style.cardTop}>
                      <div className={style.userImage}>
                        <BsPersonCircle />
                      </div>
                      <div className={style.userDetail}>
                        <h6>
                          <span> User Name :</span> {data.Username}
                        </h6>
                        <p>
                          <span> Role :</span> {data.role}
                        </p>
                        {/* <p>{data.year_of_experience} year Experiance</p> */}
                      </div>
                    </div>
                    <div className={style.cardBotton}>
                      <div className={style.bottom_left}>
                        <div className={style.iconContainer}>
                          <BiEdit
                            onClick={() => {
                              setUpdatePopup(true);
                              setEditClubs(data.club_id);
                              setEditFirstName(data.FirstName);
                              setEditSecondName(data.LastName);
                              setEditPhone(data.ContactNumber);
                              setEditEmail(data.Email);
                              setEditUserName(data.Username);
                              setUserId(data._id);
                            }}
                          />
                          <span className={style.tooltipText}>Edit</span>
                        </div>
                        <div className={style.iconContainer}>
                          <RiDeleteBinLine
                            className={style.deleteButton}
                            onClick={() => {
                              setUserId(data._id);
                              setMemberDelete(true);
                            }}
                          />
                          <span className={style.tooltipText}>Delete</span>
                        </div>
                      </div>
                      <div className={style.bottom_right}>
                        {data.is_org === false && data.role === "Intern" ? (
                          <button
                            onClick={() => {
                              navigate("/lesson/mapping", {
                                state: {
                                  organization: data.org_id,
                                  club: data.club_id,
                                  user: data.Username,
                                  tocken: data.auth_token,
                                  id: data._id,
                                },
                              });
                            }}
                          >
                            Lesson Map
                            <RiShareForwardFill className={style.forward} />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className={style.pageContainer}>
            <Pagination
              pageCount={Math.ceil(totalUsers / itemsPerPage)}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        ""
      )}
      {roleChange ? (
        <>
          <div
            onClick={() => {
              setRoleChange(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.roleSelected}>
            <div className={style.roleCLose}>
              <AiOutlineClose
                onClick={() => {
                  setRoleChange(false);
                }}
              />
            </div>
            <div className={style.roleHead}>
              <h4>User List</h4>
            </div>
            <div className={style.userList}>
              <table>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>UserName</th>
                  <th>Role</th>
                  <th>New Role</th>
                  <th>Action</th>
                </tr>

                {userData.length > 0 &&
                  userData.map((data, i) => {
                    return (
                      <tr>
                        <td>{data.FirstName}</td>
                        <td>{data.LastName}</td>
                        <td>{data.Username}</td>
                        <td>{data.role}</td>
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedRoleID = e.target.value;
                              setSelectedRoleId(selectedRoleID);
                            }}
                          >
                            <option value="">Select Role</option>
                            {roleData.length > 0 &&
                              roleData.map((role, i) => {
                                return (
                                  <option
                                    value={role.role_id}
                                    key={role.role_id}
                                  >
                                    {role.role_name}
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button
                            className={style.Change_role}
                            onClick={() => {
                              changeRole(selectedRoleId, data._id);
                            }}
                          >
                            Change Role
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {mentorChange ? (
        <>
          <div
            onClick={() => {
              setMentorChange(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.roleSelected}>
            <div className={style.roleCLose}>
              <AiOutlineClose
                onClick={() => {
                  setMentorChange(false);
                }}
              />
            </div>
            <div className={style.roleHead}>
              <h4>Mentor Mapping</h4>
            </div>
            <div className={style.userList}>
              <table>
                <tr>
                  <th>UserName</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Assigned Mentor</th>
                  <th>New Mentor</th>
                  <th>Action</th>
                </tr>

                {userData.length > 0 &&
                  userData.map((data, i) => {
                    return (
                      <tr>
                        <td>{data.Username}</td>
                        <td>{data.Email}</td>
                        <td>{data.role}</td>
                        <td>
                          <button
                            className={style.viewButton}
                            onClick={() => {
                              setMappedMentorList(true);
                              mappedMentorView(data._id);
                            }}
                          >
                            View
                          </button>
                        </td>
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedMenterID = e.target.value;
                              setSelectedMentorId(selectedMenterID);
                            }}
                          >
                            <option value="">Select Mentor</option>
                            {mentorList.length > 0 &&
                              mentorList.map((mentor, i) => {
                                return (
                                  <option value={mentor._id} key={mentor._id}>
                                    {mentor.Username}({mentor.about_experience})
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              mentorUpdate(selectedMentorId, data._id);
                            }}
                            className={style.mentorAdd}
                          >
                            Add Mentor
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {mappedMentorList ? (
        <>
          <div
            onClick={() => {
              setMappedMentorList(false);
            }}
            className={style.RoleOverlay}
          ></div>
          <div className={style.AssignedMentorList}>
            <div className={style.listClose}>
              <AiOutlineClose
                onClick={() => {
                  setMappedMentorList(false);
                }}
              />
            </div>
            <div className={style.listHead}>
              <h4>Assigned Mentor List</h4>
            </div>
            <div className={style.allMentorList}>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Department</th>
                  <th>Change Mentor</th>
                  <th>Action</th>
                </tr>

                {singleMentorList.length > 0 &&
                  singleMentorList.map((list, i) => {
                    return (
                      <tr>
                        <td>{list.Username}</td>
                        <td>{list.about_experience}</td>{" "}
                        <td>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              const selectedMenterID = e.target.value;
                              setSelectedMentorId(selectedMenterID);
                            }}
                          >
                            <option value="">Select Mentor</option>
                            {mentorList.length > 0 &&
                              mentorList.map((mentor, i) => {
                                return (
                                  <option value={mentor._id} key={mentor._id}>
                                    {mentor.Username}({mentor.about_experience})
                                  </option>
                                );
                              })}
                          </select>
                        </td>
                        <td>
                          <button>Update Mentor</button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {memberCreate ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setMemberCreate(false);
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setMemberCreate(false);
                }}
              />
            </div>
            <div className={style.membersection}>
              <input
                type="text"
                name=""
                id="FirstName"
                placeholder="First Name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <input
                type="text"
                name=""
                id="secondName"
                placeholder="Second Name"
                onChange={(e) => {
                  setSecondName(e.target.value);
                }}
              />
              <input
                type="text"
                name=""
                id="UserName"
                placeholder="User Name"
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
              />
              <div className={style.password}>
                <input
                  type={showPassword ? "text" : "password"}
                  name=""
                  id="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <BiLockOpenAlt
                  onClick={() => {
                    togglePasswordVisibility();
                  }}
                />
              </div>

              <input
                type="text"
                name=""
                id="phone"
                placeholder="Phone"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              <input
                type="mail"
                name=""
                id="mail"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <select
                className={style.roleSelection}
                name=""
                id=""
                onChange={(e) => {
                  const RoleID = e.target.value;
                  const roleName =
                    e.target.options[e.target.selectedIndex].text;
                  setSelectedRoleId(RoleID);
                  setSelectedRoleName(roleName);
                }}
              >
                <option value="">Select Role</option>
                {roles.length > 0 &&
                  roles.map((role) => {
                    return (
                      <option value={role.role_name} key={role.role_id}>
                        {role.role_name}
                      </option>
                    );
                  })}
              </select>
              <div className={style.clubSelection}>
                <div className={style.clubButton}>
                  <p>select club</p>

                  {clubSelectShow ? (
                    <MdKeyboardArrowUp
                      onClick={() => {
                        setClubSelectShow(false);
                      }}
                    />
                  ) : (
                    <MdKeyboardArrowDown
                      onClick={() => {
                        setClubSelectShow(true);
                      }}
                    />
                  )}
                </div>
                {clubSelectShow ? (
                  <div className={style.clubCheck}>
                    {clubList &&
                      clubList.length > 0 &&
                      clubList.map((club) => {
                        return (
                          <div className={style.clubValue}>
                            <p>{club.ClubName}</p>
                            <input
                              type="checkbox"
                              name={club.ClubID}
                              id={club.ClubID}
                              onChange={handleCheckboxChange}
                            />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    memberAdd();
                  }}
                >
                  Create
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setMemberCreate(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
              <div className={style.errorValue}>
                <p>{apiError}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {updatePopup ? (
        <>
          <div
            className={style.Overlay}
            onClick={() => {
              setUpdatePopup(false);
              setApiError("");
              setErrors({});
            }}
          ></div>
          <div className={style.memberCreate}>
            <div className={style.memberClose}>
              <AiOutlineClose
                onClick={() => {
                  setUpdatePopup(false);
                  setApiError("");
                  setErrors({});
                }}
              />
            </div>
            <div className={style.membersection}>
              <input
                value={editFirstName}
                type="text"
                name=""
                id=""
                placeholder="First Name"
                onChange={(e) => {
                  setEditFirstName(e.target.value);
                }}
              />
              <input
                value={editSecondName}
                type="text"
                name=""
                id=""
                placeholder="Second Name"
                onChange={(e) => {
                  setEditSecondName(e.target.value);
                }}
              />
              <input
                value={editUserName}
                type="text"
                name=""
                id=""
                placeholder="User Name"
                onChange={(e) => {
                  setEditUserName(e.target.value);
                }}
              />

              <input
                value={editPhone}
                type="text"
                name=""
                id=""
                placeholder="Phone"
                onChange={(e) => {
                  setEditPhone(e.target.value);
                }}
              />
              <input
                value={editEmail}
                type="mail"
                name=""
                id=""
                placeholder="Email"
                onChange={(e) => {
                  setEditEmail(e.target.value);
                }}
              />

              <select
                className={style.roleSelection}
                name=""
                id=""
                onChange={(e) => {
                  const selectedRoleID = e.target.value;
                  const roleName =
                    e.target.options[e.target.selectedIndex].text;
                  setSelectedRoleId(selectedRoleID);
                  setSelectedRoleName(roleName);
                }}
              >
                <option value="">Select Role</option>

                {roles &&
                  roles.length > 0 &&
                  roles.map((role) => {
                    return (
                      <option value={role.role_id}>{role.role_name}</option>
                    );
                  })}
              </select>

              <div className={style.clubSelection}>
                <div className={style.clubButton}>
                  <p>select club</p>

                  {EditClubSelectShow ? (
                    <MdKeyboardArrowUp
                      onClick={() => {
                        setEditClubSelectShow(false);
                      }}
                    />
                  ) : (
                    <MdKeyboardArrowDown
                      onClick={() => {
                        setEditClubSelectShow(true);
                      }}
                    />
                  )}
                </div>
                {EditClubSelectShow ? (
                  <div className={style.clubCheck}>
                    {clubList &&
                      clubList.length > 0 &&
                      clubList.map((club) => {
                        const isSelected = editClubs.includes(club.ClubID);
                        return (
                          <div className={style.clubValue} key={club.ClubID}>
                            <p>{club.ClubName}</p>
                            <input
                              type="checkbox"
                              name={club.ClubID}
                              id={club.ClubID}
                              checked={isSelected}
                              onChange={() => {
                                handleClub(club.ClubID);
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className={style.createButton}>
                <button
                  className={style.create}
                  onClick={() => {
                    memberEdit();
                  }}
                >
                  Update
                </button>
                <button
                  className={style.cancel}
                  onClick={() => {
                    setUpdatePopup(false);
                    setApiError("");
                    setErrors({});
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className={style.errorMessages}>
              {Object.values(errors).map((error, index) => (
                <div key={index} className={style.errorValue}>
                  <p>{error}</p>
                </div>
              ))}
              <div className={style.errorValue}>
                <p>{apiError}</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      {memberDelete ? (
        <>
          <div
            onClick={() => {
              setMemberDelete(false);
            }}
            className={style.Overlay}
          ></div>
          <div className={style.deletePopup}>
            <div className={style.deleteClose}>
              <AiOutlineClose
                onClick={() => {
                  setMemberDelete(false);
                }}
              />
            </div>
            <div className={style.deleteSection}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete ?</p>
              </div>
              <div className={style.popupDelete}>
                <button
                  className={style.orgDelete}
                  onClick={() => {
                    DeleteMember();
                  }}
                >
                  Delete
                </button>
                <button
                  className={style.orgDeleteCancel}
                  onClick={() => {
                    setMemberDelete(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default User;
